import React from "react";
import { Link } from "gatsby";
import Container from "../components/container";
import Button from "../components/button";
import Layout from "../components/layout";
import SEO from "../components/seo";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: sidan finns ej" />
    <Container>
      <h1>sidan finns ej</h1>
      <p>du finner all information på startsidan.</p>
      <br />
      <br />
      <br />
      <Link to="/">
        <Button text={"tillbaka till startsidan"} />
      </Link>
      <br />
      <br />
      <br />
    </Container>
  </Layout>
);

export default NotFoundPage;
